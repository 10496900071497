import DesignPage from 'Views/design/DesignPage'
import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { DesignPageQuery } from 'Typings/generated-gatsby-types'

export default function Design(props: PageProps<DesignPageQuery>) {
  return <DesignPage data={props.data?.strapiDesignPage} path={props.path} />
}

export const query = graphql`
  query($language: String!) {
    strapiDesignPage(locale: { eq: $language }) {
      seo {
        meta_title
        meta_description
        meta_image {
          localFile {
            publicURL
          }
        }
      }
      heading
      sub_heading
      sections {
        ...DynamicSections
      }
      projects {
        project {
          id
          name
          short_info
          slug
          years
          work_type
          app_type
          show_overlay
          preview {
            localFile {
              publicURL
            }
          }
          views {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
